import React from 'react'

const Footer = props => (
  <footer id="footer">
    <ul className="icons">
      <li>
        <a href="https://www.facebook.com/theSGPFoods/" target="_blank" className="icon brands alt fa-facebook-f">
          <span className="label">Facebook</span>
        </a>
      </li>
      <li>
        <a href="https://sg.linkedin.com/company/sgpfoods" target="_blank" className="icon brands alt fa-linkedin-in">
          <span className="label">LinkedIn</span>
        </a>
      </li>
      <li>
        <a href="https://www.instagram.com/sgpfoods/" target="_blank" className="icon brands alt fa-instagram">
          <span className="label">Instagram</span>
        </a>
      </li>
      <li>
        <a href="/contactus" className="icon solid alt fa-envelope">
          <span className="label">Email</span>
        </a>
      </li>
    </ul>
    <ul className="copyright">
      <li>&copy; SGP Foods Pte. Ltd.</li>
      <li> All rights reserved.</li>
      <li> 9 Raffles Place, B1-01, Singapore 048619</li>
      <li>+65 8831 6024 (WhatsApp)</li>
    </ul>
  </footer>
)

export default Footer
